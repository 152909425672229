.hover-submenu1{
	@media (min-width: 992px) {
		&.app.sidenav-toggled {
			.app-content {
				margin-left: 115px;
			}
			.app-sidebar {
				left: 270px;
			}
		}
	}
	.app-sidebar .ps__thumb-y {
		right: 0;
	}
	.app-content {
		margin-top: 0px;
		overflow: hidden;
		-webkit-transition: margin-left 0.2s ease;
		-o-transition: margin-left 0.2s ease;
		transition: margin-left 0.2s ease;
		.side-app {
			padding: 25px 1.5rem 0 1.5rem;
		}
	}
	.footer .container {
		width: 100%;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		margin-right: auto;
		margin-left: auto;
	}
	@media (min-width: 992px) {
		.app-content {
			margin-left: 270px;
			padding-bottom: 0rem;
		}
	}
	@media (max-width: 991px) {
		.app-content {
			min-width: 100%;
		}
		.app-sidebar{
	   z-index: 9 !important;
	   }
	}
	@media (max-width: 480px) {
		.app-sidebar__toggle {
			padding: 10px !important;
			line-height: 0.2 !important;
		}
		.app-sidebar__toggle a i {
		    margin-top: -3px !important;
		}
	}
	@media print {
		.app-content {
			margin: 0;
			padding: 0;
			background-color: $white;
		}
	}

	/*----- Componant: Top Navigation Bar ----- */
	
	.app-header {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		z-index: 999;
		padding-right: 15px;
		padding: 1.2rem 0;
		-webkit-transition: padding-left 0.2s ease;
		-o-transition: padding-left 0.2s ease;
		transition: padding-left 0.2s ease;
		border-bottom: 1px solid #ebedfa;
		background: $white;
		box-shadow:0px 6px 18px rgba(196, 205, 224, .1);
	}
	@media (min-width: 768px) {
		.app-header {
			padding-right: 13px;
		}
	}
	@media print {
		.app-header {
			display: none;
		}
	}
	.app-sidebar__toggle {
		a {
			color: #555b95;
		}
		margin: 0 6px;
		padding: 8px;
		text-align: center;
		position: relative;
		border-radius: 8px;
		line-height: 2;
		background: #f1f4fb;
		justify-content: center;
		height: 45px;
		width: 45px;
		margin-right: 1.3rem;
		&:hover {
			color: $primary;
		}
		&:focus, &:hover {
			text-decoration: none;
		}
	}
	@media (max-width: 991px) {
		.app-sidebar__toggle {
			-webkit-box-ordinal-group: 0;
			-ms-flex-order: -1;
			order: -1;
		}
	}

	.app-nav__item {
		display: block;
		padding: 15px;
		line-height: 20px;
		color: #a8a8a8;
		-webkit-transition: background-color 0.3s ease;
		-o-transition: background-color 0.3s ease;
		transition: background-color 0.3s ease;
		&:hover, &:focus {
			background: $black-1;
			color: #f6f6f6;
		}
	}
	&.sidenav-toggled .app-sidebar {
		position: absolute;
	}
	.app-sidebar {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: 270px;
		z-index: 1000;
		background: #17263a;
		border-right: 1px solid rgba(255,255,255,.1);
		box-shadow: 0px 6px 18px rgba(196, 205, 224 , .2);
		-webkit-transition: left 0.2s ease, width 0.2s ease;
		transition: left 0.2s ease, width 0.2s ease;
		&::-webkit-scrollbar {
			width: 6px;
		}
		&::-webkit-scrollbar-thumb {
			background: $black-2;
		}
	}
	&.sidenav-toggled .app-sidebar3{
		height:inherit;
	}
	@media print {
		.app-sidebar {
			display: none;
		}
	}
	.app-sidebar__user {
		.dropdown-menu {
			top: 10px !important;
		}
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: #aab3cc;
		width: 100%;
		padding: 25px 20px;
		display: inline-block;
		border-bottom: 1px solid rgba(255,255, 255, 0.1)
	}
	.app-sidebar .side-item.side-item-category {
		color: $muted;
		font-size: 11px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: .5px;
		margin-bottom: 10px;
		padding: 0 20px 0 18px;
		opacity: .4;
		&:not(:first-child) {
			margin-top: 25px;
			margin-bottom: 10px;
		 }
	}
	.sidenav-toggled {
		.app-sidebar__user .user-pic {
			margin: 0px 0px 0px 0;
			&::before{
				display: none;
			}
			.avatar-xxl{
				border: 3px solid #2c4669;
			}
		}
	}
	.app-sidebar__user .user-pic {
		margin: 0px 0px 10px 0;
		&::before{
			content: '';
			display: block;
			position: absolute;
			right: 37%;
				bottom: 8px;
			width: .75rem;
			height: .75rem;
			border: 2px solid #fff;
			border-radius: 50%;
			background: $success;
			cursor: pointer;
		}
	}
	.app-sidebar__user .user-pic .avatar-xxl{
		border: 3px solid  #2c4669;
	}
	.app-sidebar__user-name.text-muted {
		&.text-sm {
			font-size: 15px;
			font-weight: 400;
		}
		font-size: 17px;
		line-height: 1.3;
		white-space: nowrap;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		margin-bottom: 0;
		overflow: hidden;
		font-weight: 600;
		color: $muted ;
		font-size: 15px;
		margin-top: 5px !important;
	}
	.side-menu {
		padding-bottom: 40px;
		padding: 0px;
	}
	.side-menu__item {
	   margin: 1px 10px;
	}
	@media (min-width: 992px) {
		.app.sidebar-mini.sidenav-toggled .side-menu__label {
			display: block !important;
			position: relative;
		}
		&.app.sidebar-mini.sidenav-toggled .side-menu__item {
			position: relative;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			border-radius: 7px;
			font-size: 14px;
			font-weight: 400;
			padding: 10px 0px 10px 0!important;
			-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
			-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
			transition: border-left-color 0.3s ease, background-color 0.3s ease;
			margin:1px 30px;
			&.active, &:hover, &:focus {
				text-decoration: none;
				color: $primary;
			}
			&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
				color: $primary;
				
			}
		}
		&.app.sidebar-mini.sidenav-toggled .side-menu__item .sidemenu_icon {
			position: relative;
			border-radius: 7px;
			margin: 5px 20px;
			&.active, &:hover, &:focus {
				text-decoration: none;
				color: $primary;
			}
			&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
				color: $primary;
				
			}
		}
		.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
			display: none;
		}
	}
	.slide-menu a.active {
		color: $primary ;
		 background: transparent;
	}
	.slide-item {
		&.active, &:hover, &:focus {
			text-decoration: none;
			color: $primary !important;
			background: transparent;
		}
	}
	.slide.is-expanded a {
		color: #eceff9;
		text-decoration: none;
		&.slide-item {
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			&:hover {
				text-decoration: none;
				-webkit-transition: all 0.3s;
				transition: all 0.3s;
			}
		}
		&.app.sidebar-mini.sidenav-toggled .side-menu__item {
			margin: 1px 34px;
		}
	}
	.side-menu .side-menu__icon {
		font-size: 25px;
		line-height: 0px;
		text-align: center;
		vertical-align: middle;
		margin-right: 8px;
		margin-left: -8px;
	}
	.side-menu__icon {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: 25px;
	}
	.side-menu__label {
		white-space: nowrap;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		font-weight: 400;
	}
	.slide.is-expanded {
		.slide-menu {
			li a:hover {
				color: $primary;
			}
			display: block;
		}
		.angle {
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
	}
	.slide-menu {
		display: none;
		padding-left: 10px;
		font-size:13.2px !important;
	}
	.slide-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 8px 14px 8px 35px;
		color: #eceff9;
		.icon {
			margin-right: 5px;
		}
	}
	.angle {
		-webkit-transform-origin: center;
		-ms-transform-origin: center;
		transform-origin: center;
		-webkit-transition: -webkit-transform 0.3s ease;
		transition: -webkit-transform 0.3s ease;
		-o-transition: transform 0.3s ease;
		transition: transform 0.3s ease;
		transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	}
	.app-sidebar__user .user-info h5{
		color:$white;
		font-weight: 500;
		margin-bottom: 3px !important;
	}
	@media (min-width: 992px) {
		&.sidebar-mini.sidenav-toggled {
			.side-menu .sidemenu_icon {
				margin-right: 0;
			}
			.Annoucement_card, .nav-pills-circle,  .app-user-rating {
				display:none !important;
			}
			.app-sidebar__user-name, .angle, .app-sidebar__user-name, .app-sidebar__user-name, .user-info {
				display: none;
			}
			> .avatar-xl {
				width: 3rem !important;
				height: 3rem !important;
				line-height: 3rem;
				font-size: 1rem;
				margin-bottom: 0px !important;
			}
			.app-sidebar__user {
				top: .5rem;
				right: 30px !important;
				margin-top: 0px;
			}
			.side-menu {
				margin-top: 0px;
			}
			.app-sidebar__user {
				padding: 12px 0px 12px 0;
				margin-bottom: 0px;
				display: none;
			}
			.profile-img {
				top: 0px;
				right: 19px;
			}
			.app-sidebar {
				left: 0;
				width: 115px;
				overflow: hidden;
				-webkit-transition: left 0.3s ease, width 0.3s ease;
				transition: left 0.3s ease, width 0.3s ease;
			}
			.app-sidebar__user .user-pic .avatar-xxl{
				width: 2.8rem;
				height: 2.8rem;
			}
			.side-item-category{
				display: none;
			}
			.side-menu__item {
				&:hover {
					overflow: visible;
				}
			}
			.slide {
				&:hover {
					.slide-menu {
						max-height: 100%;
						opacity: 1;
						visibility: visible;
						z-index: 10;
					}
				}
			}
			.slide-menu {
				position: absolute;
				opacity: 0;
				border-bottom-right-radius: 4px;
				z-index: 9;
				visibility: hidden;
				-webkit-transition: visibility 0.3s ease;
				-o-transition: visibility 0.3s ease;
				transition: visibility 0.3s ease;
			}
		}
		&.app.sidebar-mini.sidenav-toggled {
			.side-menu__item {
				display: block !important;
			}
			.side-menu_label {
				display: block;
				font-size: 12px;
			}
			.side-menu__label {
				display: none !important;
				position: relative;
				font-size: 11px;
				text-align: center;
			}
		}
		&.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
			margin: 0 auto;
		}
		&.app.sidebar-mini.sidenav-toggled .nav-badge {
			position: absolute;
			top: 8px;
			right: 28px;
			padding: 0.2rem 0.4rem;
			font-size: 11px;
		}
		.side-menu__item {
			&:hover, &:focus {
				text-decoration: none;
				color: $primary;
			}
		}
		.side-menu__item {
			&:hover .side-menu__icon, &:focus .side-menu__icon {
				color: $primary;
			}
		}
		.side-menu__item:hover {
			.side-menu__label, .side-menu__icon, .angle {
				color: $white !important;
			}
		}
		.slide-item {
			&:hover, &:focus {
				text-decoration: none;
				color: $primary;
				background: transparent;
			}
		}
	}
	&.app.sidebar-mini {
		&.sidenav-toggled {
			.side-menu .side-menu__icon {
				margin-left: 0;
				text-align: center;
				height: 2.5rem;
				font-size: 1.2rem;
				position: relative;
				border-radius: 3px;
				border: 0px solid #e9ebfa;
				border-radius: 3px;
			}
		}
		.side-menu_label {
			display: none;
		}
	}
	@media (min-width:992px) {
		&.app.sidebar-mini {
			&.sidenav-toggled {
				.side-menu .side-menu__icon {
					margin-right: 0;
				}
			}
		}
	}
	&.sidenav-toggled .app-sidebar__user .avatar-md {
		line-height: 2rem;
		font-size: 1rem;
	}
	.slide-menu a:before {
		content: '\e0b1';
		margin-right: 10px;
		font-size: 11px;
		position: relative;
		font-family: 'typicons' !important;
		opacity: 0.6;
	}
	&.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
		.nav-badge {
			position: absolute;
			top: 5px;
			left: 57px;
			display: block !important;
			padding: 3px 5px !important;
		}
		.nav-badge1 {
			display: none;
		}
	}
	.nav-badge {
		border-radius: 30px;
		padding: 0.4em 0.6em;
		font-size: 12px;
	}
	@media (max-width: 575.98px) {
		.app-header .header-brand-img.mobile-logo {
			margin-left: 0;
		}
		.app-sidebar__toggle a i{
			line-height: 1.5;
		}
		.app .app-sidebar {
			margin-top: 87px;
		}
		.app-content .side-app {
			padding: 25px 0.75rem 0 0.75rem;
		}
	}
	@media (max-width: 992px) and (min-width: 768px) {
		.app-header {
			.header-brand-img.desktop-lgo, .header-brand {
				margin-left: 0;
			}
		}
	}
	@media (min-width: 992px) {
		.footer {
			padding: 1.25rem 1.25rem;
		}
		footer.footer {
			padding: 1.25rem 1.25rem 1.25rem 270px;
		}
		.sidenav-toggled footer.footer {
			padding: 1.25rem 1.25rem 1.25rem 70px;
		}
	}
	@media (max-width: 768px) and (min-width: 481px) {
		.header-brand {
			margin-left: 0;
			min-width: auto !important;
		}
	}
	@media (max-width: 991px) {
		.app-sidebar__logo {
			display: none;
		}
		.app-sidebar__user .user-pic {
			&::before{
			display: block !important;
			}
		}
	}
	@media (min-width: 992px) {
		.app-header .header-brand {
			display: none;
		}
		.app-header {
			padding-left: 270px;
		}
		&.sidenav-toggled {
			.app-header {
				padding-left: 115px;
			}
			.app-sidebar__logo {
				width:115px;
			}
		}
	}
	.app-sidebar__logo {
		padding: 1.0rem 35px;
		border-bottom: 1px solid rgba(255,255,255, 0.1);
		height: 87px;
		text-align: center;
		position: fixed;
		width: 270px;
		transition: left 0.2s ease, width 0.2s ease;
		z-index: 999;
		background: #17263a;
	}
	.close-toggle {
		display: none;
	}
	.app-sidebar__toggle a i {
		line-height: 1.3;
		margin-top: 0;
		font-size: 22px;
	}
	&.sidenav-toggled {
		.close-toggle {
			display: block;
		}
		.open-toggle {
			display: none;
		}
	}
	@media (min-width: 992px) {
		.app-sidebar__logo .header-brand-img {
			&.desktop-lgo, &.darkmobile-logo, &.mobile-logo {
				display: none;
			}
		}
		&.sidenav-toggled {
			.header-brand-img {
				&.dark-logo {
					display: none;
				}
				&.mobile-logo {
					display: block;
					margin-top: 5px;
				}
			}
		}
		.app-sidebar {
			&.app-sidebar3 {
				margin-top: 88px;
			}
			&.app-sidebar2 {
				box-shadow: none;
				z-index: 9999;
				height: 88px;
			}
		}
	}
	.app-sidebar .app-sidebar3 {
		margin-top: 95px;
		padding-bottom: 88px;
	}

	/*-- Subslide ---*/
	.sub-slide-item {
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		list-style: none;
		padding: 8px 0 8px 35px !important;
	}
	.sub-slide-menu {
		display: none;
		padding: 0;
		font-size: 13px !important;
		padding-left: 0;
		list-style: none;
	}
	.slide-menu .sub-slide.active.is-expanded {
		background: rgba(255, 255, 255, 0.09);
	}
	.sub-slide.is-expanded .sub-slide-menu {
		display: block;
	}
	.sub-side-menu__item {
		padding: 8px 35px 8px 35px;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.sub-angle {
		float: right;
		line-height:inherit;
		margin-top: 3.5px;
		right: -15px;
		-webkit-transform-origin: center;
		-ms-transform-origin: center;
		transform-origin: center;
		-webkit-transition: -webkit-transform 0.3s ease;
		transition: -webkit-transform 0.3s ease;
		-o-transition: transform 0.3s ease;
		transition: transform 0.3s ease;
		transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	}
	.sub-slide.is-expanded .sub-angle {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	.sub-slide .sub-side-menu__label{
		flex: 1 1 auto;
	}
	
	/*-- Subslide2 ---*/
	.sub-slide-item2 {
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		list-style: none;
		padding: 8px 0 8px 55px ;
		font-size: 12.5px;
	}
	.sub-slide-menu2 {
		display: none;
		padding: 0;
		font-size: .8rem !important;
		padding-left: 0;
		list-style: none;
	}
	.slide-menu .sub-slide2.active.is-expanded {
		background: rgba(255, 255, 255, 0.09);
	}
	.sub-slide2.is-expanded .sub-slide-menu2 {
		display: block;
	}
	.sub-side-menu__item2 {
		padding: 8px 20px 8px 35px;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.sub-angle2 {
		float: right;
		line-height: 15px;
		margin-top: 3.5px;
		right: -15px;
		-webkit-transform-origin: center;
		-ms-transform-origin: center;
		transform-origin: center;
		-webkit-transition: -webkit-transform 0.3s ease;
		transition: -webkit-transform 0.3s ease;
		-o-transition: transform 0.3s ease;
		transition: transform 0.3s ease;
		transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	}
	.sub-slide2.is-expanded .sub-angle2 {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	.sub-slide2 .sub-side-menu__label2{
		font-size:13px;
		flex: 1 1 auto;
	}
	 .app-sidebar .dropdown-menu {
		box-shadow: 0px 10px 10px 20px rgba(16, 22, 58, 0.02), 10px 10px 15px -5px rgba(16, 22, 58, 0.08);
		background: $primary;
		color: $white;
		border:0;
		.border-bottom {
			border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
		}
		a {
			color: $white;
			&.text-primary {
				color: $white-8 !important;
			}
		}
	}
	
	.slide .side-menu__item.active,  .slide .side-menu__item.active:hover {
		color: $white !important;
		background: $primary !important;
	}
	.side-menu .slide a {
		color: #aab3cc;
		background: none;
	
	}
	.side-menu .sidemenu_icon{
		font-size:1.05rem;
		color:#aab3cc;
		margin-right:0.6rem;
	}
	 .slide a.active .sidemenu_icon,  .side-menu__item:hover .sidemenu_icon{
		color:$white;
	}
	@media (min-width: 992px){
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user {
				padding: 12px 0 12px 0;
				margin-bottom: 0;
				display: none;
			}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user img{
			width: 2.8rem !important;
			height: 2.8rem !important;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .user-info{
            display: none !important;
		} 
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-item-category{
			display: none;
		}

		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide.is-expanded .slide-menu {
			display: none;
		}
		&.app.sidebar-minisidenav-toggled.sidenav-toggled1 .side-menu__label {
			display: none!important;
			position: relative;
			font-size: 11px;
			text-align: center;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .angle {
			display: none;
		}
		&.sidebar-mini.sidenav-toggled .app-sidebar .app-sidebar3 {
			overflow: visible !important;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1  .slide:hover .slide-menu {
			display: block !important;
			width: 100%;
			left: 115px;
			min-width: 203px;
			max-height: inherit;
			visibility: visible;
		}
		
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1  .slide:hover .slide-menu {
			top: 0;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .slide-menu{
			position: absolute;
			background: #17263a;
			max-height: inherit;
			opacity: 1;
			visibility: hidden;
			z-index: 999;
			left: 72px;
			border-radius: 0px 7px 7px 0px !important;
			transition: none !important;
			height:auto !important;
		}
		&.sidebar-mini.sidenav-toggled .slide-menu {
			position: absolute;
			left: 70px;
			min-width: 180px;
			opacity: 1;
			z-index: 9999;
			visibility: hidden;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .slide-item {
			padding: 8px 20px 8px 25px;
		}
		
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .sub-side-menu__item {
			padding: 8px 27px 8px 17px; ;
     	}
		&.sidenav-toggled.sidenav-toggled1 .app-sidebar{
			overflow: visible !important;
            position: absolute !important;
		}
		&.sidenav-toggled.sidenav-toggled1 .slide-menu {
			padding-left: 0px;
		}
		
		&.sidenav-toggled.sidenav-toggled1 .side-menu__item {
			border-radius: 7px;
			font-size: 17px;
			margin: 5px 25px;
		}
		&.sidenav-toggled.sidenav-toggled1 .side-menu {
			padding: 0px;
		}
		&.sidenav-toggled .app-content {
			min-height: 1670px !important;
		}
		
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .sub-angle {
			line-height:10px;
		}
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu-label1 {
			display: block !important;
			font-size: 16px;
			font-weight: 500;
			border-bottom: 1px solid rgba(255,255,255,.1);
			padding: 10px 10px!important;
			text-align: left;
		}

	}
	@media (max-width: 991px) and (min-width: 768px){
		&.sidenav-toggled .responsive-navbar.navbar .navbar-collapse {
			padding-left: 8rem;
		}

	}
		&.rtl .sub-side-menu__item2 {
			padding: 8px 35px 8px 20px;
	    }
			
	@media (min-width: 992px) {
		&.rtl.sidenav-toggled .app-header {
			padding-right: 70px;
			padding-left: inherit;
		}
		&.rtl.sidebar-mini.sidenav-toggled .app-content {
			margin-right: 70px;
			margin-left: inherit;
		}
		&.rtl.sidenav-toggled.sidenav-toggled1 .slide-menu {
			padding-right: 0px;
			padding-left: inherit;
		}
		&.rtl .sub-side-menu__item {
			padding:  8px 17px 8px 27px !important;
		}
		 .app-sidebar__toggle {
		    margin-left: 1.3rem;
		    margin-right:inherit;
	}
	}
	&.rtl{
		.slide-menu a:before {
		    margin-left: 10px;
		    margin-right:inherit;
              }
		.app-sidebar {
			right: 0;
			border-left: 1px solid rgba(255, 255, 255, 0.1);
		}
		.sub-slide-item {
			padding: 8px 35px 8px 0px  !important;
		}
		@media (min-width: 992px){
			 &.rtl .sub-slide-item2 {
			     padding: 8px 55px 8px 0px !important;
		     }
			&.sidenav-toggled .app-header {
				padding-right: 115px !important;
				padding-left: inherit;
			}
			&.app.sidenav-toggled .app-content {
				margin-right: 115px !important;
				margin-left: inherit;
			}
			&.sidebar-mini.sidenav-toggled .side-menu .sidemenu_icon{
              margin-left: 0 !important;
			}
			&.sidenav-toggled.sidenav-toggled1 .slide:hover .slide-menu{
				right:114px;
				left: inherit;
			}
			&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .slide-menu{
				right: 72px;
				left: inherit;
				border-radius:  7px 0 0 7px!important;
			}
			.app-content {
				margin-right: 270px;
				margin-left: inherit;
		     }
			.app-header {
				padding-right: 270px;
				padding-left: inherits;
			}
			
			&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu-label1 {
				text-align: right;
			}
			.slide-menu .side-menu-label1 a:before{
				display: none;

			}
     	}
		 
		 @media (max-width: 991px) and (min-width: 768px){
			&.sidenav-toggled .responsive-navbar.navbar .navbar-collapse {
				padding-right: 8rem;
				padding-left: inherit;
			}
	
		}
		@media (max-width: 991px) {
			&.rtl .sub-slide-item {
			padding: 8px 55px 8px 0px !important;
		   }
			&.rtl .sub-side-menu__item2 {
				padding: 10px 55px 10px 20px !important;
			}
			&.rtl .sub-slide-item2 {
				padding: 8px 75px 8px 0px !important;
			}
			&.app {
				overflow-x: hidden;
				.app-sidebar {
					  left: -270px;
					margin-top: 84px;
					.app-sidebar3{
						margin-top: 0;
					}
				}
				&.sidenav-toggled {
					.app-sidebar {
						left: 0;
					}
				}
				&.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
					line-height: 1;
				}
			}
		}
		
		 @media (max-width: 991px){
			 &.app .app-sidebar {
				right: -270px !important;
				 left: inherit !important;
			  }
			 &.app.sidenav-toggled .app-sidebar {
				 right: 0 !important;
				 left: inherit;
			 }
 
		 }
		.slide-menu .side-menu-label1 {
			display: none;
		}
	
	}
	.app-sidebar .slide{
		position: relative;
		display: block;
	}
	 .slide-menu .side-menu-label1 a:before{
       content: '' !important;
	 }
	 &.rtl .app-sidebar__toggle {
	     margin-left: 1.3rem;
	     margin-right:inherit;
     }
}
.hover-submenu1 .app-sidebar__toggle {
    margin: 0 6px;
    padding: 8px;
    text-align: center;
    position: relative;
    border-radius: 8px;
    line-height: 2;
    background: #f1f4fb;
    justify-content: center;
    height: 45px;
    width: 45px;
    margin-right: 1.3rem;
} 