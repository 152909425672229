/*--- WIZARD ELEMENTS ---*/

.wizard {
	border: 1px solid #e9edf4;
	background-color: $white;
	> {
		.steps {
			padding: 20px;
			>ul {
				padding: 0;
				margin-bottom: 0;
				display: flex;
				li {
					float: none;
					display: block;
					width: auto;
					.current-info {
						display: none;
					}
					.title {
						margin-left: 5px;
						white-space: nowrap;
						transition: all 0.2s ease-in-out;
						margin: 0;
						min-height: auto;
					}
					+li {
						margin-left: 5px;
					}
				}
			}
			a {
				color: $primary;
				font-weight: 500;
				font-size: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				&:hover, &:active {
					color: $primary;
					font-weight: 500;
					font-size: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.number, &:hover .number, &:active .number {
					flex-shrink: 0;
					font-weight: 700;
					font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
					color: $white;
					display: block;
					text-align: center;
					line-height: 2;
					width: 30px;
					height: 30px;
					background-color: #b4bdce;
					border-radius: 100%;
					transition: all 0.2s ease-in-out;
					margin-right: 5px;
				}
			}
			.disabled a {
				color: #737688;
				&:hover, &:active {
					color: #737688;
				}
			}
			.current a {
				color: $primary;
				&:hover, &:active {
					color: $primary;
				}
				.title, &:hover .title, &:active .title {
					display: inline-block;
				}
				.number, &:hover .number, &:active .number {
					background-color: $primary;
				}
			}
			.done a {
				color: $success;
				&:hover, &:active {
					color: $success;
				}
				.title, &:hover .title, &:active .title {
					display: none;
				}
				.number, &:hover .number, &:active .number {
					background-color: $success;
				}
			}
		}
		.content {
			border-top: 1px solid #e9edf4;
			border-bottom: 1px solid #e9edf4;
			min-height: 150px;
			padding: 20px;
			> {
				.title {
					font-size: 18px;
					color: $color;
					font-weight: 600;
					padding-bottom: 15px;
					display: none;
					margin: 0;
					&.current {
						display: block;
					}
				}
				.body {
					float: none;
					position: static;
					width: auto;
					height: auto;
					input.parsley-error {
						border-color: #ff5c77;
						+ul {
							list-style: none !important;
						}
					}
				}
			}
		}
		.actions {
			padding: 20px;
			>ul {
				margin: 0;
				padding: 0;
				list-style: none;
				display: flex;
				justify-content: space-between;
				>li:last-child a {
					background-color: $success;
				}
			}
			a {
				display: block;
				background-color: $primary;
				padding: 9px 25px;
				line-height: 1.573;
				color: $white;
				border-radius: 4px;
				font-weight: 500;
				&:hover, &:active {
					display: block;
					background-color: $primary;
					padding: 9px 25px;
					line-height: 1.573;
					color: $white;
					border-radius: 4px;
					font-weight: 500;
				}
			}
			.disabled a {
				background-color: #e8ebf2;
				color: $color;
				&:hover, &:active {
					background-color: #e8ebf2;
					color: $color;
				}
			}
		}
	}
	&.vertical> {
		.steps {
			padding: 20px;
			ul li {
				+li {
					margin-left: 10px;
				}
				.title {
					display: none;
				}
			}
			.current a .title {
				display: inline-block;
			}
		}
		.content {
			margin: 0;
			padding: 20px;
		}
		.actions {
			padding: 20px;
			ul {
				float: none;
				margin: 0;
				padding: 0;
			}
		}
	}
}
@media (min-width: 768px) {
	.wizard>.steps {
		padding: 25px;
	}
}
@media (min-width: 992px) {
	.wizard>.steps {
		padding: 20px;
	}
}
@media (prefers-reduced-motion: reduce) {
	.wizard>.steps>ul li .title {
		transition: none;
	}
}
@media (min-width: 576px) {
#wizard3.wizard>.steps>ul li .title {
		//display: none;
		margin-left: 10px;
	}
}
@media (min-width: 768px) {
	.wizard>.steps>ul li .title {
		display: inline-block;
	}
}
@media (min-width: 576px) {
	.wizard>.steps>ul li+li {
		margin-left: 20px;
	}
}
@media (min-width: 992px) {
	.wizard>.steps>ul li+li {
		margin-left: 30px;
	}
}
@media (min-width: 1200px) {
	.wizard>.steps a {
		justify-content: flex-start;
		&:hover, &:active {
			justify-content: flex-start;
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.wizard>.steps a {
		.number, &:hover .number, &:active .number {
			transition: none;
		}
	}
}
@media (min-width: 576px) {
	.wizard>.steps a {
		.number, &:hover .number, &:active .number {
			font-size: 18px;
			font-weight: 600;
			line-height: 2.1;
			width: 40px;
			height: 40px;
		}
	}
}
@media (min-width: 992px) {
	.wizard>.steps .disabled {
		display: block;
	}
}
@media (max-width: 991px) {
	.wizard>.steps .disabled {
		display: none;
	}
}
@media (min-width: 768px) {
	.wizard>.steps .done a {
		.title, &:hover .title, &:active .title {
			display: inline-block;
		}
	}
}
@media (min-width: 768px) {
	.wizard>.content {
		padding: 25px;
		margin: inherit;
		width: inherit;
	}
}
@media (min-width: 992px) {
	.wizard>.content {
		padding: 20px;
	}
}
@media (min-width: 768px) {
	.wizard>.actions {
		padding: 25px;
	}
}
@media (min-width: 992px) {
	.wizard>.actions {
		padding: 20px;
	}
}
@media (min-width: 576px) {
	.wizard.vertical>.steps {
		float: left;
		width: 20%;
	}
}
@media (min-width: 768px) {
	.wizard.vertical>.steps {
		width: 15%;
	}
}
@media (min-width: 992px) {
	.wizard.vertical>.steps {
		padding: 20px;
		width: 35%;
	}
}
@media (min-width: 576px) {
	.wizard.vertical>.steps ul {
		flex-direction: column;
	}
}
@media (min-width: 576px) {
	.wizard.vertical>.steps ul li+li {
		margin-top: 10px;
		margin-left: 0;
	}
}
@media (min-width: 768px) {
	.wizard.vertical>.steps ul li+li {
		margin-top: 20px;
	}
}
@media (min-width: 992px) {
	.wizard.vertical>.steps ul li .title {
		display: block;
	}
}
@media (min-width: 992px) {
	.wizard.vertical>.steps a {
		justify-content: flex-start;
	}
}
@media (max-width: 992px) {
  #wizard3.wizard.vertical>.steps .current a .title {
		display: none;
	}
}
@media (min-width: 992px) {
	.wizard.vertical>.steps .current a .title {
		display: inline-block;
	}
}
@media (min-width: 576px) {
	.wizard.vertical>.content {
		border-top-width: 0;
		border-bottom-width: 0;
		width: 80%;
		float: right;
		border-left: 1px solid #e9edf4;
	}
}
@media (min-width: 768px) {
	.wizard.vertical>.content {
		width: 85%;
	}
}
@media (min-width: 992px) {
	.wizard.vertical>.content {
		width: 65%;
		padding: 25px;
	}
}
@media (min-width: 576px) {
	.wizard.vertical>.actions {
		width: 80%;
		float: right;
		border-left: 1px solid #e9edf4;
	}
}
@media (min-width: 768px) {
	.wizard.vertical>.actions {
		width: 85%;
	}
}
@media (min-width: 992px) {
	.wizard.vertical>.actions {
		width: 70%;
		padding: 25px;
	}
}

/***** CUSTOM STYLES *****/

.parsley-required {
	font-size: 12px;
	color: #ff5c77;
	margin-top: 3px;
}

.form-group label.control-label {
	color: $primary;
}
.wizard-container .wizard-navigation {
	background: #ebeff8;
}