/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */

.ms-parent {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100% !important;
}

.ms-choice {
  position:inherit;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d3dfea;
  color: #313e6a;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: 14px;
  line-height: 13px;
  overflow:auto;

  &.disabled {
    background-color: #f4f5f9;
    background-image: none;
    border: 1px solid #d3dfea;
    cursor: default;
  }

  > {
    span {
      position: absolute;
      top: 13px;
      left: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding-left: 18px;

      &.placeholder {
        color: #999;
      }
    }

    div {
      position: absolute;
      top: 7px;
      right: 0;
      width: 20px;
      height: 25px;
      background: url('../plugins/multipleselect/multiple-select.png') left top no-repeat;

      &.open {
        background: url('../plugins/multipleselect/multiple-select.png') right top no-repeat;
     }
    }
  }
}

.ms-drop {
  width: 100%;
  overflow: hidden;
  display: none;
  margin-top: -3px;
  padding: 0;
  position: absolute;
  z-index: 10;
  background: #fff;
  color: #000;
  border: 1px solid #eff0f6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 0 0 4px 4px;

  &.bottom {
    top: 100%;
    border: 1px solid #e9ebfa;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
  }

  &.top {
    bottom: 100%;
    border: 1px solid #e9ebfa;
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
  }
}

.ms-search {
  display: inline-block;
  margin: 0;
  min-height: 26px;
  padding: 4px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 10000;
}

.ms-parent {
  .placeholder {
    height: 0;
    width: 0;
    border: 0;
  }

  &.form-control {
    width: 100% !important;
  }
}

.ms-search {
  input {
    width: 100%;
    height: auto !important;
    min-height: 24px;
    padding: 0 20px 0 5px;
    margin: 0;
    outline: 0;
    font-family: sans-serif;
    font-size: 1em;
    border: 1px solid #eff0f6;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #fff url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px;
    background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, white 85%, #eeeeee 99%);
    background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, white 85%, #eeeeee 99%);
    background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -o-linear-gradient(bottom, white 85%, #eeeeee 99%);
    background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -ms-linear-gradient(top, #ffffff 85%, #eeeeee 99%);
    background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, linear-gradient(top, #ffffff 85%, #eeeeee 99%);
    -webkit-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.ms-drop {
  ul {
    overflow: auto;
    margin: 0;
    padding: 5px 8px;

    > li {
      list-style: none;
      display: block;
      background-image: none;
      position: static;
      height: 30px;
      line-height: 30px;
      margin: 0 6px;
      border-radius: 4px;

      .disabled {
        opacity: .35;
        filter: Alpha(Opacity = 35);
      }

      &.multiple {
        display: block;
        float: left;
      }

      &.group {
        clear: both;
      }

      &.multiple label {
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      label {
        font-weight: normal;
        display: block;
        white-space: nowrap;

        &.optgroup {
          font-weight: bold;
        }
      }
    }
  }

  input {
    &[type="checkbox"] {
      vertical-align: middle;
      vertical-align: middle;
      margin-right: 6px;
      margin-bottom: 1px;
      margin-left: 6px;
      width: 20px;
      height: 20px;
      background: #fff;
      border: #fff;
      box-shadow: none;
    }

    &[type="radio"] {
      margin-right: 6px;
      margin-bottom: 1px;
      margin-left: 6px;
      margin-top: 1px;
    }
  }

  .ms-no-results {
    display: none;
  }

  .multiple {
    width: 90px !important;
  }

  .custom-checkbox:before {
    background: transparent;
  }

  .custom-control-label {
    margin-bottom: 0;
    display: block;
    margin-top: -3px;
    line-height: 28px;
  }
}

.ms-select-all .custom-control-label {
  &::before, &::after {
    top: -1.6rem !important;
  }
}

.ms-drop {
  .custom-control-label {
    &::before {
      position: absolute;
      top: 0.3rem;
      left: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: #fff;
    }

    &::after {
      position: absolute;
      top: 0.3rem;
      left: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }

  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: transparent;
  }
}