@keyframes dtb-spinner {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes dtb-spinner {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes dtb-spinner {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dtb-spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes dtb-spinner {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

div {
    &.dt-button-info {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 400px;
        margin-top: -100px;
        margin-left: -200px;
        background-color: white;
        border: 2px solid #111;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        text-align: center;
        z-index: 21;

        h2 {
            padding: 0.5em;
            margin: 0;
            font-weight: normal;
            border-bottom: 1px solid #ddd;
            background-color: #f3f3f3;
        }

        > div {
            padding: 1em;
        }
    }

    &.dt-button-collection-title {
        text-align: center;
        padding: 0.3em 0 0.5em;
        font-size: 0.9em;

        &:empty {
            display: none;
        }
    }
}

button.dt-button,
div.dt-button,
a.dt-button,
input.dt-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 0.333em;
    margin-bottom: 0.333em;
    padding: 0.5em 1em;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.88em;
    line-height: 1.6em;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(230, 230, 230, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    outline: none;
    text-overflow: ellipsis;
}

button.dt-button.disabled,
div.dt-button.disabled,
a.dt-button.disabled,
input.dt-button.disabled {
    cursor: default;
    opacity: 0.4;
}

button.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
        box-shadow: inset 1px 1px 3px #999;
    }
}

div.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
        box-shadow: inset 1px 1px 3px #999;
    }
}

a.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
        box-shadow: inset 1px 1px 3px #999;
    }
}

input.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
        box-shadow: inset 1px 1px 3px #999;
    }
}

button.dt-button {
    &:active:not(.disabled):hover:not(.disabled),
    &.active:not(.disabled):hover:not(.disabled) {
        box-shadow: inset 1px 1px 3px #999;
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    }
}

div.dt-button {
    &:active:not(.disabled):hover:not(.disabled),
    &.active:not(.disabled):hover:not(.disabled) {
        box-shadow: inset 1px 1px 3px #999;
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    }
}

a.dt-button {
    &:active:not(.disabled):hover:not(.disabled),
    &.active:not(.disabled):hover:not(.disabled) {
        box-shadow: inset 1px 1px 3px #999;
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    }
}

input.dt-button {
    &:active:not(.disabled):hover:not(.disabled),
    &.active:not(.disabled):hover:not(.disabled) {
        box-shadow: inset 1px 1px 3px #999;
        background-color: rgba(0, 0, 0, 0.1);
        background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: -o-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    }
}

button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover,
input.dt-button:hover {
    text-decoration: none;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled),
input.dt-button:hover:not(.disabled) {
    border: 1px solid #666;
    background-color: rgba(0, 0, 0, 0.1);
    background: -webkit-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(153, 153, 153, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
}

button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled),
input.dt-button:focus:not(.disabled) {
    border: 1px solid #426c9e;
    text-shadow: 0 1px 0 #c4def1;
    outline: none;
    background-color: #79ace9;
    background: -webkit-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: -moz-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: -ms-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: -o-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
    background: linear-gradient(to bottom, #d1e2f7 0%, #79ace9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="#d1e2f7", EndColorStr="#79ace9");
}

.dt-button embed {
    outline: none;
}

div {
    &.dt-buttons {
        position: relative;
        float: left;

        &.buttons-right {
            float: right;
        }
    }

    &.dataTables_layout_cell div.dt-buttons {
        float: none;

        &.buttons-right {
            float: none;
        }
    }

    &.dt-button-collection {
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        margin-top: 3px;
        padding: 8px 8px 4px 8px;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.4);
        background-color: white;
        overflow: hidden;
        z-index: 2002;
        border-radius: 5px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        box-sizing: border-box;

        button.dt-button,
        div.dt-button,
        a.dt-button {
            position: relative;
            left: 0;
            right: 0;
            width: 100%;
            display: block;
            float: none;
            margin-bottom: 4px;
            margin-right: 0;
        }

        button.dt-button {
            &:active:not(.disabled),
            &.active:not(.disabled) {
                background-color: #dadada;
                background: -webkit-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -moz-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -ms-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -o-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="#f0f0f0", EndColorStr="#dadada");
                box-shadow: inset 1px 1px 3px #666;
            }
        }

        div.dt-button {
            &:active:not(.disabled),
            &.active:not(.disabled) {
                background-color: #dadada;
                background: -webkit-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -moz-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -ms-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -o-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="#f0f0f0", EndColorStr="#dadada");
                box-shadow: inset 1px 1px 3px #666;
            }
        }

        a.dt-button {
            &:active:not(.disabled),
            &.active:not(.disabled) {
                background-color: #dadada;
                background: -webkit-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -moz-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -ms-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: -o-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
                background: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="#f0f0f0", EndColorStr="#dadada");
                box-shadow: inset 1px 1px 3px #666;
            }
        }

        &.fixed {
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -75px;
            border-radius: 0;

            &.two-column {
                margin-left: -200px;
            }

            &.three-column {
                margin-left: -225px;
            }

            &.four-column {
                margin-left: -300px;
            }
        }

        > :last-child {
            display: block !important;
            -webkit-column-gap: 8px;
            -moz-column-gap: 8px;
            -ms-column-gap: 8px;
            -o-column-gap: 8px;
            column-gap: 8px;

            > * {
                -webkit-column-break-inside: avoid;
                break-inside: avoid;
            }
        }

        &.two-column {
            width: 400px;

            > :last-child {
                padding-bottom: 1px;
                -webkit-column-count: 2;
                -moz-column-count: 2;
                -ms-column-count: 2;
                -o-column-count: 2;
                column-count: 2;
            }
        }

        &.three-column {
            width: 450px;

            > :last-child {
                padding-bottom: 1px;
                -webkit-column-count: 3;
                -moz-column-count: 3;
                -ms-column-count: 3;
                -o-column-count: 3;
                column-count: 3;
            }
        }

        &.four-column {
            width: 600px;

            > :last-child {
                padding-bottom: 1px;
                -webkit-column-count: 4;
                -moz-column-count: 4;
                -ms-column-count: 4;
                -o-column-count: 4;
                column-count: 4;
            }
        }

        .dt-button {
            border-radius: 0;
        }
    }

    &.dt-button-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        background: -ms-radial-gradient(
            center,
            ellipse farthest-corner,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
        );
        background: -moz-radial-gradient(
            center,
            ellipse farthest-corner,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
        );
        background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
        background: -webkit-gradient(
            radial,
            center center,
            0,
            center center,
            497,
            color-stop(0, rgba(0, 0, 0, 0.3)),
            color-stop(1, rgba(0, 0, 0, 0.7))
        );
        background: -webkit-radial-gradient(
            center,
            ellipse farthest-corner,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.7) 100%
        );
        background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
        z-index: 2001;
    }
}

@media screen and (max-width: 640px) {
    div.dt-buttons {
        float: none !important;
        text-align: center;
    }
}

button.dt-button.processing,
div.dt-button.processing,
a.dt-button.processing {
    color: rgba(0, 0, 0, 0.2);
}

button.dt-button.processing:after,
div.dt-button.processing:after,
a.dt-button.processing:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    box-sizing: border-box;
    display: block;
    content: " ";
    border: 2px solid #282828;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: dtb-spinner 1500ms infinite linear;
    -o-animation: dtb-spinner 1500ms infinite linear;
    -ms-animation: dtb-spinner 1500ms infinite linear;
    -webkit-animation: dtb-spinner 1500ms infinite linear;
    -moz-animation: dtb-spinner 1500ms infinite linear;
}
