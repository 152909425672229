@font-face {
  font-family: 'pignose-calendar-icon';
  src: url("../plugins/pg-calendar-master/fonts/pignose.calendar.eot?gpa4vl");
  src: url("../plugins/pg-calendar-master/fonts/pignose.calendar.eot?gpa4vl#iefix") format("embedded-opentype"), url("../plugins/pg-calendar-master/fonts/pignose.calendar.ttf?gpa4vl") format("truetype"), url("../plugins/pg-calendar-master/fonts/pignose.calendar.woff?gpa4vl") format("woff"), url("../plugins/pg-calendar-master/fonts/pignose.calendar.svg?gpa4vl#pignose.calendar") format("svg");
  font-weight: normal;
  font-style: normal;
}

.pignose-calendar {
  .icon-arrow-left, .icon-arrow-right {
    font-family: 'pignose-calendar-icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  .icon-arrow-left:before {
    content: '\e90b';
    color: $primary;
  }

  .icon-arrow-right:before {
    content: '\e90a';
    color: $primary;
  }
}

.pignose-calendar-wrapper {
  display: none;
  position: fixed;
  width: 80%;
  max-width: 360px;
  top: 50%;
  left: 50%;
  border-radius: 2px;
  z-index: 50001;
  overflow: hidden;
  -webkit-box-shadow: 0 4px 16px #000000;
  box-shadow: 0 4px 16px #000000;
  -webkit-transform: translate3d(0, 160px, 0);
  transform: translate3d(0, 160px, 0);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.5s ease-out;
  transition: opacity 0.3s ease, -webkit-transform 0.5s ease-out;
  transition: opacity 0.3s ease, transform 0.5s ease-out;
  transition: opacity 0.3s ease, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;

  &.pignose-calendar-wrapper-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .pignose-calendar {
    max-width: auto;
    width: 100%;
    border: none;

    .pignose-calendar-button-group {
      border-top: 1px solid #e2e2e2;
      overflow: hidden;

      .pignose-calendar-button {
        width: 50%;
        display: block;
        float: left;
        height: 3.2em;
        text-align: center;
        line-height: 3.2em;
        color: #333333;
        font-weight: 600;
        text-decoration: none;
        -webkit-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &:hover {
          background-color: #efefef;
        }
      }

      .pignose-calendar-button-apply {
        color: #ffffff;
        background-color: #2fabb7;

        &:hover {
          background-color: #49c4d0;
        }
      }
    }
  }
}

.pignose-calendar-wrapper-overlay {
  background-color: #000000;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;

  &.pignose-calendar-wrapper-overlay-active {
    opacity: 0.7;
  }
}

.pignose-calendar {
  width: 100%;

  /* background-color: #ffffff; */
  border: 8px;
  box-shadow: none;
  font-size: 100%;
  margin: 0 auto;

  .pignose-calendar-top {
    padding: 1.5rem 0 1.5rem 0;
    background-color: #ffffff;
    border-bottom: 0;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
    box-shadow: none;
    position: relative;
    overflow: hidden;
    border-radius: 8px 8px 0 0;

    .pignose-calendar-top-date {
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1.3em 0 0 0;
      text-align: center;
      text-transform: uppercase;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

au {
  /* display: block; */
  text-align: center;
  margin: auto;
}

.pignose-calendar {
  .pignose-calendar-top {
    .pignose-calendar-top-year {
      /* font-size: 115%; */
      /* color: rgba(0, 0, 0, 0.5); */
      margin: auto;
      font-size: 130%;
      font-weight: 600;
    }

    .pignose-calendar-top-month {
      font-size: 130%;
      font-weight: 600;
    }

    .pignose-calendar-top-nav {
      display: inline-block;
      width: 1.6em;
      height: 1.6em;
      position: relative;
      z-index: 5;
      text-decoration: none;

      .pignose-calendar-top-value {
        display: inline-block;
        color: #777777;
        font-size: 115%;
        font-weight: 600;
        vertical-align: middle;
        margin-top: -10px;
      }

      .pignose-calendar-top-icon {
        font-size: 160%;
        border: 1px solid #e8eef3;
        border-radius: 5px;
        color: #768099;
      }

      &.pignose-calendar-top-prev {
        float: left;
        margin-left: 1.6em;

        .pignose-calendar-top-value {
          margin-left: 0.2em;
        }
      }

      &.pignose-calendar-top-next {
        float: right;
        margin-right: 1.6em;

        .pignose-calendar-top-value {
          margin-right: 0.2em;
        }
      }
    }
  }

  .pignose-calendar-header {
    padding: 0 1.2em;
    margin-top: 0;
    font-weight: 600;
    overflow: hidden;

    .pignose-calendar-week {
      float: left;
      width: 14.28%;
      height: 2.8em;
      text-align: center;
      line-height: 2.8em;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &:last-child {
        width: 14.32%;
      }
    }
  }

  .pignose-calendar-body {
    padding: 0.61em 1.2rem;

    .pignose-calendar-row {
      overflow: hidden;
    }
  }

  .pignose-calendar-unit {
    float: left;
    display: block;
    height: 3.8em;
    width: 14.28%;
    text-align: center;
    line-height: 2.8em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:last-child {
      width: 14.32%;
    }

    .pignose-calendar-button-schedule-container {
      line-height: 0.5em;

      .pignose-calendar-button-schedule-pin {
        display: inline-block;
        background-color: #777777;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        margin-right: 0.2em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    a {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 2px;
      line-height: 2.5rem;
      text-align: center;
      text-decoration: none;
      -webkit-transition: background-color 0.3s ease, color 0.3s ease;
      transition: background-color 0.3s ease, color 0.3s ease;
      font-size: 15px;
      border-radius: 4px;

      &:active {
        background-color: #f0f4f9;
      }
    }

    &.pignose-calendar-unit-disabled a {
      opacity: 0.5;
      background-color: #efefef;
    }

    &.pignose-calendar-unit-active {
      a {
        color: #ffffff;
        font-weight: 600;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        box-shadow: none;
        background: $primary;
        border-radius: 4PX;
      }

      &.pignose-calendar-unit-sun a, &.pignose-calendar-unit-sat a {
        color: #ffffff;
      }
    }

    &.pignose-calendar-unit-range {
      a {
        background-color: #f0f4f9;
        border-radius: 0;
        width: 100%;
      }

      &.pignose-calendar-unit-disabled a {
        color: #b2b9bb;
        background-color: #e1e1e1;
      }

      &.pignose-calendar-unit-range-first a {
        border-top-left-radius: 1.2em;
        border-bottom-left-radius: 1.2em;
      }

      &.pignose-calendar-unit-range-last a {
        border-top-right-radius: 1.2em;
        border-bottom-right-radius: 1.2em;
      }
    }

    &.pignose-calendar-unit-sun a, &.pignose-calendar-unit-sat a {
      color: $primary;
    }
  }

  &.pignose-calendar-default {
    .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle-active a {
      color: #fe7f00 !important;
      background: #ffede7;
    }

    &.pignose-calendar-reverse .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle-inactive a {
      color: #cccccc !important;
    }
  }

  &.pignose-calendar-dark {
    border-color: #272b44;
    background-color: #272b44;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);

    .pignose-calendar-top {
      background-color: #272b44;
      border-bottom-color: #5a4a61;
      -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);

      .pignose-calendar-top-month {
        color: #ffffff;
      }

      .pignose-calendar-top-year {
        color: #bdc2c5;
      }

      .pignose-calendar-top-nav {
        .pignose-calendar-top-value, .pignose-calendar-top-icon {
          color: #a2a9ab;
        }
      }
    }

    .pignose-calendar-header {
      .pignose-calendar-week {
        color: #bdc2c5;
      }

      &.pignose-calendar-week-sun, &.pignose-calendar-week-sat {
        color: #ff6060;
      }
    }

    .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit {
      a {
        color: #306eff;
      }

      &.pignose-calendar-unit-sun a, &.pignose-calendar-unit-sat a {
        color: #ff6060;
      }

      &.pignose-calendar-unit-disabled a {
        color: #868e8f;
        background-color: #5d6365;
      }

      &.pignose-calendar-unit-active a {
        color: #ffffff;
        background-color: #306eff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
      }

      &.pignose-calendar-unit-toggle a {
        color: #8b8f94;
      }

      &.pignose-calendar-unit-range {
        a {
          background-color: #5a5d62;
        }

        &.pignose-calendar-unit-disabled a {
          color: #727a7c;
          background-color: #4f5558;
        }
      }
    }

    .pignose-calendar-button-group {
      border-top: 1px solid #323537;
      overflow: hidden;

      .pignose-calendar-button {
        color: #ffffff;

        &:hover {
          background-color: #5a5d62;
        }
      }

      .pignose-calendar-button-apply {
        color: #ffffff;
        background-color: #306eff;
      }
    }
  }

  &.pignose-calendar-blue {
    background-color: #fafafa;

    .pignose-calendar-top {
      background-color: #009fe3;
      border-bottom-color: #e1e1e1;

      .pignose-calendar-top-month, .pignose-calendar-top-year {
        color: #ffffff;
      }

      .pignose-calendar-top-nav {
        .pignose-calendar-top-value, .pignose-calendar-top-icon {
          color: #ffffff;
        }
      }
    }

    .pignose-calendar-header .pignose-calendar-week {
      color: #5c6270;

      &.pignose-calendar-week-sun, &.pignose-calendar-week-sat {
        color: #fa4832;
      }
    }

    .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit {
      a {
        color: #5c6270;
      }

      &.pignose-calendar-unit-sun a, &.pignose-calendar-unit-sat a {
        color: #fa4832;
      }

      &.pignose-calendar-unit-disabled a {
        background-color: #efefef;
      }

      &.pignose-calendar-unit-active a {
        color: #ffffff;
        background-color: #009fe3;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
      }

      &.pignose-calendar-unit-toggle a {
        color: #cccccc;
      }

      &.pignose-calendar-unit-range {
        a, &.pignose-calendar-unit-disabled a {
          background-color: #efefef;
        }
      }
    }
  }
}