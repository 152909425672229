.ff_fileupload_hidden {
  display: none;
}

.ff_fileupload_wrap {
  .ff_fileupload_dropzone_wrap {
    position: relative;
  }

  .ff_fileupload_dropzone {
    display: block;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    border: 2px dashed #e9ebfa;
    border-radius: 5px;
    padding: 0;
    background-color: #eaeefa;
    background-image: url('../plugins/fancyuploder/fancy_upload.png');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.85;
    cursor: pointer;
    outline: none;

    &::-moz-focus-inner {
      border: 0;
    }

    &:hover, &:focus, &:active {
      opacity: 1;
      background-color: #fff;
      border-color: #f3f4f7;
    }
  }

  .ff_fileupload_dropzone_tools {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .ff_fileupload_dropzone_tool {
    display: block;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #efecec;
    border-radius: 3px;
    padding: 0;
    background-color: #FDFDFD;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.85;
    cursor: pointer;
    outline: none;

    &::-moz-focus-inner {
      border: 0;
    }

    &:hover, &:focus, &:active {
      opacity: 1;
      background-color: #FFFFFF;
      border-color: #4e4c73;
    }
  }

  .ff_fileupload_recordaudio {
    background-image: url('../plugins/fancyuploder/fancy_microphone.png');
  }

  .ff_fileupload_recordvideo {
    background-image: url('../plugins/fancyuploder/fancy_webcam.png');
  }

  .ff_fileupload_recordvideo_preview {
    position: absolute;
    display: block;
    right: 60px;
    top: 10px;
    width: 320px;
    max-width: calc(100% - 70px);
    height: calc(100% - 20px);
    background-color: #222222;

    &.ff_fileupload_hidden {
      display: none;
    }
  }

  .ff_fileupload_recording {
    animation: ff_fileupload_recording_animate 1.2s infinite alternate;
  }

  table.ff_fileupload_uploads {
    width: 100%;
    border-collapse: collapse !important;
    border: 0 none;

    tr {
      margin: 0;
      border: 0 none;
      padding: 0;
    }

    td {
      margin: 0;
      border: 0 none;
      padding: 0;
      vertical-align: top;
      padding: 1em 0;
      white-space: nowrap;
      line-height: normal;
    }

    tr.ff_fileupload_bounce {
      animation: ff_fileupload_bounce_animate 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translateY(0);
    }

    td {
      &.ff_fileupload_preview {
        width: 1px;

        .ff_fileupload_preview_image {
          display: block;
          box-sizing: border-box;
          border: 0 none;
          padding: 0;
          background-color: #f3f4f7;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          width: 50px;
          height: 50px;
          border-radius: 5px;
          opacity: 0.75;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          overflow: hidden;
          outline: none;
          cursor: default;

          &::-moz-focus-inner {
            border: 0;
          }
        }

        .ff_fileupload_preview_image_has_preview {
          cursor: pointer;
        }

        .ff_fileupload_preview_image {
          &:hover, &:focus, &:active {
            opacity: 1;
          }
        }

        .ff_fileupload_preview_text {
          display: block;
          margin: 0 auto;
          width: 70%;
          overflow: hidden;
        }
      }

      &.ff_fileupload_actions button {
        display: inline-block;
        vertical-align: top;
        width: 26px;
        height: 26px;
        box-sizing: border-box;
        border: 1px solid #e9ebfa;
        border-radius: 3px;
        padding: 0;
        background-color: #eaeefa;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.85;
        cursor: pointer;
        outline: none;

        &::-moz-focus-inner {
          border: 0;
        }

        &:hover, &:focus, &:active {
          opacity: 1;
          background-color: #FDFDFD;
          border-color: #e9ebfa;
        }

        &.ff_fileupload_start_upload {
          margin-right: 0.5em;
        }
      }

      &.ff_fileupload_preview .ff_fileupload_actions_mobile {
        display: none;

        button {
          display: block;
          margin-top: 0.3em;
          width: 100%;
          height: 28px;
          box-sizing: border-box;
          border: 1px solid #efecec;
          border-radius: 3px;
          padding: 0;
          background-color: #f3f3f3;
          background-repeat: no-repeat;
          background-position: center center;
          opacity: 0.85;
          cursor: pointer;
          outline: none;

          &::-moz-focus-inner {
            border: 0;
          }

          &:hover, &:focus, &:active {
            opacity: 1;
            background-color: #FDFDFD;
            border-color: #4e4c73;
          }
        }
      }
    }

    button {
      &.ff_fileupload_start_upload {
        background-image: url('../plugins/fancyuploder/fancy_okay.png');
      }

      &.ff_fileupload_remove_file {
        background-image: url('../plugins/fancyuploder/fancy_remove.png');
      }
    }

    td {
      &.ff_fileupload_preview {
        .ff_fileupload_preview_text_with_color {
          color: #FFFFFF;
        }

        .ff_fileupload_preview_text_a {
          background-color: #F03C3C;
        }

        .ff_fileupload_preview_text_b {
          background-color: #F05A3C;
        }

        .ff_fileupload_preview_text_c {
          background-color: #F0783C;
        }

        .ff_fileupload_preview_text_d {
          background-color: #F0963C;
        }

        .ff_fileupload_preview_text_e {
          background-color: #E0862B;
        }

        .ff_fileupload_preview_text_f {
          background-color: #DCA12B;
        }

        .ff_fileupload_preview_text_g {
          background-color: #C7AB1E;
        }

        .ff_fileupload_preview_text_h {
          background-color: #C7C71E;
        }

        .ff_fileupload_preview_text_i {
          background-color: #ABC71E;
        }

        .ff_fileupload_preview_text_j {
          background-color: #8FC71E;
        }

        .ff_fileupload_preview_text_k {
          background-color: #72C71E;
        }

        .ff_fileupload_preview_text_l {
          background-color: #56C71E;
        }

        .ff_fileupload_preview_text_m {
          background-color: #3AC71E;
        }

        .ff_fileupload_preview_text_n {
          background-color: #1EC71E;
        }

        .ff_fileupload_preview_text_o {
          background-color: #1EC73A;
        }

        .ff_fileupload_preview_text_p {
          background-color: #1EC756;
        }

        .ff_fileupload_preview_text_q {
          background-color: #1EC78F;
        }

        .ff_fileupload_preview_text_r {
          background-color: #1EC7AB;
        }

        .ff_fileupload_preview_text_s {
          background-color: #1EC7C7;
        }

        .ff_fileupload_preview_text_t {
          background-color: #1EABC7;
        }

        .ff_fileupload_preview_text_u {
          background-color: #1E8FC7;
        }

        .ff_fileupload_preview_text_v {
          background-color: #1E72C7;
        }

        .ff_fileupload_preview_text_w {
          background-color: #3C78F0;
        }

        .ff_fileupload_preview_text_x {
          background-color: #3C5AF0;
        }

        .ff_fileupload_preview_text_y {
          background-color: #3C3CF0;
        }

        .ff_fileupload_preview_text_z {
          background-color: #5A3CF0;
        }

        .ff_fileupload_preview_text_0 {
          background-color: #783CF0;
        }

        .ff_fileupload_preview_text_1 {
          background-color: #963CF0;
        }

        .ff_fileupload_preview_text_2 {
          background-color: #B43CF0;
        }

        .ff_fileupload_preview_text_3 {
          background-color: #D23CF0;
        }

        .ff_fileupload_preview_text_4 {
          background-color: #F03CF0;
        }

        .ff_fileupload_preview_text_5 {
          background-color: #F03CD2;
        }

        .ff_fileupload_preview_text_6 {
          background-color: #F03CB4;
        }

        .ff_fileupload_preview_text_7 {
          background-color: #F03C96;
        }

        .ff_fileupload_preview_text_8 {
          background-color: #F03C78;
        }

        .ff_fileupload_preview_text_9 {
          background-color: #F03C5A;
        }
      }

      &.ff_fileupload_summary {
        padding: 1em;
        font-size: 0.9em;
        white-space: normal;
        color: #7f88a5;

        .ff_fileupload_filename {
          width: 100%;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          input {
            box-sizing: border-box;
	     width: 100%;
	     padding: 0.5em 8px;
	     margin-bottom: 0.1em;
	     font-size: 1em;
	     font-weight: normal;
	     line-height: normal;
	     border: 0px solid #e8ebf3;
	     border-radius: 4px;
	     box-shadow: none;
	     color: #080e1b;
	     font-weight: 600;

            /* color: #080e1b; */
            font-weight: 600;

            &:focus, &:hover {
              border: 0px solid #e8ebf3;
            }
          }
        }

        .ff_fileupload_errors {
          color: #A94442;
          font-weight: bold;
        }

        .ff_fileupload_progress_background {
          margin-top: 0.5em;
          background-color: #CCCCCC;
          height: 2px;
        }

        .ff_fileupload_progress_bar {
          background-color: #4e4c73;
          width: 0;
          height: 2px;
        }
      }

      &.ff_fileupload_actions {
        width: 1px;
        text-align: right;
      }
    }
  }
}

@keyframes ff_fileupload_recording_animate {
  from {
    border-color: #EF1F1F;
  }

  to {
    border-color: #C9A1A1;
  }
}

@keyframes ff_fileupload_bounce_animate {
  10%, 90% {
    transform: translateY(-1px);
  }

  20%, 80% {
    transform: translateY(2px);
  }

  30%, 50%, 70% {
    transform: translateY(-3px);
  }

  40%, 60% {
    transform: translateY(3px);
  }
}

/* Colored buttons based on file extension for non-images. */

@media (max-width: 420px) {
  .ff_fileupload_wrap table.ff_fileupload_uploads td {
    &.ff_fileupload_preview .ff_fileupload_preview_image {
      width: 36px;
      height: 36px;
      font-size: 11px;
    }

    &.ff_fileupload_summary {
      padding-right: 0;
    }

    &.ff_fileupload_actions {
      display: none;
    }

    &.ff_fileupload_preview .ff_fileupload_actions_mobile {
      display: block;
    }
  }
}

.ff_fileupload_dialog_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10000;
}

.ff_fileupload_dialog_main {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  text-align: center;

  img {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
  }

  audio {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    width: 100%;
  }

  video {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
  }
}